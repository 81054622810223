import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { PlymouthUser } from "@utils/types";
import { useCallback, useState } from "react";
import {
  useCompanyMemberByCompanyIdAndUserIdQuery,
  useUpdateCompanyMemberByCompanyIdAndUserIdMutation,
  useUserDatumUpdatesMutation,
  useUserUpdatesMutation,
} from "@codegen/index";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { YesNo } from "@pages/onboarding/types";
import { Info } from "lucide-react";
import { HoverCard, HoverCardArrow, HoverCardContent, HoverCardTrigger } from "@components/hoverCard";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { UpdateCompanyMemberByCompanyIdAndUserIdInput, UpdateUserByIdInput, UpdateUserDatumInput } from "@codegen/schema";
import { useEmployerOnboarding } from '../../employer-onboarding';

const personalSchema = z.object({
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Required" }),
  phone: z.string().min(1, { message: "Required" }),
  isAuthorizedSignatory: YesNo
});

type ConfirmContactFormValues = z.infer<typeof personalSchema>;

export const ConfirmContactForm = () => {
  const { onSubmit, company, contact, onBack } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [userUpdatesMutation] = useUserUpdatesMutation();
  const [userDatumUpdatesMutation] = useUserDatumUpdatesMutation();
  const [updateCompanyMembers] = useUpdateCompanyMemberByCompanyIdAndUserIdMutation()
  const logError = useLogError()
  const { data: companyMemberData, refetch: refreshCompanyMember } = useCompanyMemberByCompanyIdAndUserIdQuery({
    variables: {
      userId: contact?.id ?? '',
      companyId: company?.value ?? ''
    },
    skip: !company?.value || !contact?.id
  })

  const form = useForm<ConfirmContactFormValues>({
    resolver: zodResolver(personalSchema),
    defaultValues: {
      firstName: contact?.name?.split(" ")[0],
      lastName: contact?.name?.split(" ")[1],
      email: contact?.email,
      phone: contact?.phoneNumber,
      isAuthorizedSignatory: companyMemberData?.companyMemberByCompanyIdAndUserId?.isAuthorizedSignatory ? 'yes' : 'no'
    },
  });

  const isAuthorizedSignatory = form.watch('isAuthorizedSignatory')

  const fields: {
    name: keyof ConfirmContactFormValues;
    label: string;
    placeholder?: string;
    type?: string;
  }[] = [
      {
        name: "firstName",
        label: "First name",
      },
      {
        name: "lastName",
        label: "Last name",
      },
      {
        name: "email",
        label: "Email address",
        placeholder: "hi@google.com",
      },
      {
        name: "phone",
        label: "Phone number",
        placeholder: "+16504438260",
      },
    ];

  const doSubmitPersonal = useCallback(
    async (data: ConfirmContactFormValues) => {
      if (contact == null) return;
      try {
        setStatus("loading");
        const { errors: updateUserError } = await userUpdatesMutation({
          variables: {
            input: {
              clientMutationId: '',
              id: contact?.id ?? '',
              userPatch: {
                fullName: `${data.firstName} ${data.lastName}`,
                email: data.email,
              } as UpdateUserByIdInput['userPatch'],
            },
          },
        });

        const { errors: upateUserDatumError } = await userDatumUpdatesMutation({
          variables: {
            input: {
              clientMutationId: '',
              id: contact?.id ?? '',
              userDatumPatch: {
                phoneNumber: data.phone,
              } as UpdateUserDatumInput['userDatumPatch'],
            },
          },
        });

        const { errors: updateCompanyMemberError } = await updateCompanyMembers({
          variables: {
            input: {
              clientMutationId: '',
              userId: contact?.id ?? '',
              companyId: company?.value ?? '',
              companyMemberPatch: {
                isAuthorizedSignatory: isAuthorizedSignatory === 'yes',
              } as UpdateCompanyMemberByCompanyIdAndUserIdInput['companyMemberPatch'],
            },
          },
        });

        if (updateUserError || upateUserDatumError) {
          throw {
            updateUserError,
            upateUserDatumError,
          }
        }

        if (updateCompanyMemberError) {
          logError(`Unable to update company member to authorized signatory`, {
            contactId: contact?.id,
            companyId: company?.value,
          })
        }
        refreshCompanyMember()
        onSubmit({ data, key: 'currentContact' });
      } catch (exception) {
        setStatus("error")
        logError(exception, {
          message: 'Error saving contact'
        })
      }
    },

    [onSubmit, company, contact, userDatumUpdatesMutation, userUpdatesMutation, isAuthorizedSignatory, refreshCompanyMember]
  );

  return (
    <EmployerOnboardingContainer
      title="Your details"
      subtitle={
        <div>
        </div>
      }
      cardTitle={"Please confirm your personal details"}
      progress={
        0
      }
      form={form}
      onSubmit={doSubmitPersonal}
      name="confirm_contact"
    >
      <FieldsContainer status={status} form={form}>
        {fields.map((x, i) => (
          <FormField
            key={i}
            control={form.control}
            name={x.name}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-md">{x.label}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="h-11"
                    placeholder={x.placeholder}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
        <FormField
          key={fields.length + 1}
          control={form.control}
          name={'isAuthorizedSignatory'}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-md flex items-center gap-1">Are you an authorized company signatory?
                <HoverCard openDelay={0}>
                  <HoverCardTrigger>
                    <Button type="button" variant="link" className="p-0">
                      <Info size={16} />
                    </Button>
                  </HoverCardTrigger>
                  <HoverCardContent side="right">
                    <p>An authorized signatory is an individual who has been granted legal authority to sign documents on behalf of an organization or entity.</p>
                    <HoverCardArrow className="fill-white" width={15} height={7} />
                  </HoverCardContent>
                </HoverCard>
              </FormLabel>
              <FormControl className="flex items-center gap-2">
                <FormLabel className="text-md text-primary" htmlFor="isAuthorizedSignatoryYes"><input {...form.register("isAuthorizedSignatory")} type='radio' id="isAuthorizedSignatoryYes" name="isAuthorizedSignatory" value={'yes'} />I am an authorized company signatory.</FormLabel>
              </FormControl>
              <FormControl className="flex items-center gap-2">
                <FormLabel className="text-md text-primary" htmlFor="isAuthorizedSignatoryNo"><input {...form.register("isAuthorizedSignatory")} type='radio' id="isAuthorizedSignatoryNo" name="isAuthorizedSignatory" value={'no'} />I am <b>NOT</b> an authorized company signatory.</FormLabel>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FieldsContainer>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={false}
          type='submit'
        >
          {
            (isAuthorizedSignatory === 'no') ? 'Next - Add signatory' : 'Next'
          }
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};

