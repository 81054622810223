import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { PlymouthUser } from "@utils/types";
import { useCallback, useState } from "react";
import {
  useCreateUserMutation,
  useCreateCompanyMemberMutation,
  useUserDatumUpdatesMutation,
  useGetCompanyQuery,
} from "@codegen/index";

import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { EmployerOnboardingPageType } from "@pages/onboarding/types";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { CompanyUserRole, CreateCompanyMemberInput, CreateUserMutationVariables, UpdateUserDatumInput, User } from "@codegen/schema";
import * as React from 'react'
import { useEmployerOnboarding } from '../../employer-onboarding';

const personalSchema = z.object({
  title: z.string().min(1, "Please provide a title"),
  firstName: z.string().min(1, "Please provide a first name"),
  lastName: z.string().min(1, "Please provide a last name"),
  email: z.string().min(1, "Please provide an email"),
  phone: z.string().min(1, "Please provide a phone number"),
});

type NewContactFormValues = z.infer<typeof personalSchema>;

export const NewContactForm = () => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [createUserMutation] = useCreateUserMutation();
  const [updateUserDatum] = useUserDatumUpdatesMutation();
  const [addCompanyMember] = useCreateCompanyMemberMutation()

  const { data: companyData } = useGetCompanyQuery({
    variables: {
      companyId: company.value,
      count: 1
    }
  })

  const logError = useLogError()
  const [showForm, setShowForm] = useState(false)

  const form = useForm<NewContactFormValues>({
    resolver: zodResolver(personalSchema),
  });

  const signatory = React.useMemo(() => {
    const user = companyData?.companyById?.companyMembersByCompanyId?.nodes?.[0]?.userByUserId
    let name = user?.fullName?.split(' ')
    const parsedMember = user ? {
      title: user.userDatumById?.role,
      firstName: name?.join(' '),
      lastName: name?.pop(),
      email: user.email,
      phone: user.userDatumById?.phoneNumber
    } : undefined
    return parsedMember
  }, [companyData])

  const fields: {
    name: keyof NewContactFormValues;
    label: string;
    placeholder?: string;
  }[] = [
      {
        name: "title",
        label: "Title",
        placeholder: "Software engineer"
      },
      {
        name: "firstName",
        label: "First name",
      },
      {
        name: "lastName",
        label: "Last name",
      },
      {
        name: "email",
        label: "Email address",
        placeholder: "hi@google.com",
      },
      {
        name: "phone",
        label: "Phone number",
        placeholder: "+16504438260",
      },
    ];

  const createUser = useCallback(
    async (x: NewContactFormValues) => {
      try {
        setStatus("loading");
        const { data, errors: createUserError } = await createUserMutation({
          variables: {
            input: {
              clientMutationId: '',
              user: {
                fullName: `${x.firstName} ${x.lastName}`,
                email: x.email,
              } as CreateUserMutationVariables['input']['user'],
            },
          },
        });

        if (!data?.createUser?.user?.id) {
          throw `Error creating user, unable to update user datum`
        }

        const { errors: updateUserDatumError } = await updateUserDatum({
          variables: {
            input: {
              clientMutationId: '',
              id: data?.createUser?.user?.id,
              userDatumPatch: {
                phoneNumber: x.phone,
                role: x.title
              } as UpdateUserDatumInput['userDatumPatch'],
            },
          },
        });

        const { errors: addCompanyMemberError } = await addCompanyMember({
          variables: {
            input: {
              clientMutationId: '',
              companyMember: {
                userId: data?.createUser?.user?.id,
                companyId: company.value,
                role: CompanyUserRole.Admin,
                isAuthorizedSignatory: true
              } as CreateCompanyMemberInput['companyMember'],
            },
          }
        });

        if (addCompanyMemberError) {
          // Fails silently and log to fix later
          logError('Unable to add user to company members', {
            userId: data?.createUser?.user?.id,
            companyId: company.value,
          })
        }

        if (createUserError) {
          throw {
            createUserError: createUserError[0],
            updateUserDatumError: updateUserDatumError?.[0]
          }
        }

        onSubmit(x);
      } catch (exception) {
        logError(exception)
        setStatus("error")
      }
    },
    [onSubmit, updateUserDatum, createUserMutation, addCompanyMember, setStatus, logError]
  );

  return (
    <EmployerOnboardingContainer
      title="Company Contact"
      subtitle="Let’s kick things off right away. Share the best contact for the company. This contact will also be used to sign the immigration forms."
      cardTitle={"You indicated you're not an authorized signatory for the company, please add the best signatory information."}
      progress={
        0
      }
      form={form}
      onSubmit={createUser}
      name="add_new_contact"
    >
      {
        showForm || !signatory ? (
          <FieldsContainer status={status} form={form}>
            {fields.map((x, i) => (
              <FormField
                key={i}
                control={form.control}
                name={x.name}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-md">{x.label}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-11"
                        placeholder={x.placeholder}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
          </FieldsContainer>
        ) : (
          <div className="mt-3">
            <h2 className="text-lg mb-2">Existing signatory</h2>
            <div className="p-3 border shadow-sm w-96 bg-white rounded-xl mb-3">
              <div className="text-lg font-bold">{signatory.firstName} {signatory.lastName}</div>
              <div>{signatory.title}</div>
              <div>{signatory.email}</div>
              <div>{signatory.phone}</div>
            </div>
            <div>
              <Button className="text-accent underline px-0" variant={'link'} onClick={() => setShowForm(true)}>
                Add another signatory
              </Button>
            </div>
          </div>
        )
      }
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={false}
          type="submit"
          onClick={() => {
            if (signatory && !showForm) {
              onSubmit(signatory)
            }
          }}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};

