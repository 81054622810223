import { Button } from "@components/button";
import { Progress } from "@components/progress";
import { Form } from "@components/form";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { useStatsigClient } from "@statsig/react-bindings";

type OnboardingContainerProps<TFields extends FieldValues> = {
  children: React.ReactNode;
  progress: number;
  title: string;
  subtitle: React.ReactNode;
  cardTitle: string;
  form?: UseFormReturn<TFields>;
  onSubmit?: any;
  name?: string;
}

export const EmployerOnboardingContainer = <TFields extends FieldValues>(props: OnboardingContainerProps<TFields>) => {
  const { children, title, subtitle, cardTitle, progress, form, onSubmit, name } = props;
  const { client } = useStatsigClient();
  React.useEffect(() => {
    if (client && name) {
      client.logEvent('employer_onboarding_view', name)
    }
  }, [client, name])

  return (
    <ErrorBoundary>
      <div className="w-screen min-h-screen overflow-scroll bg-[url(/bg-journey.svg)]">
        <div className="h-screen w-full">
          <div className="w-full h-full flex flex-col">
            <Progress value={progress} className="w-full" />
            <div className="flex flex-col gap-y-2 m-auto w-[571px] h-full overflow-y-scroll">
              <div className="flex flex-col justify-end grow">
                <div className="space-y-2 mb-4">
                  <h1 className="text-2xl font-semibold">{title}</h1>
                  <h2 className="text-md">{subtitle}</h2>
                </div>
                <h3 className="text-md font-semibold">{cardTitle}</h3>
              </div>
              {
                form ? (
                  <Form {...form}>
                    <form
                      onSubmit={form?.handleSubmit(onSubmit)}
                      className="flex flex-col justify-between basis-3/4 rr-mask"
                    >
                      {children}
                    </form>
                  </Form>
                ) : (
                  <div
                    className="flex flex-col justify-between basis-3/4"
                  >
                    {children}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

