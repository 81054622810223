import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm, useFormState } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { PlymouthUser, Team } from "@utils/types";
import { useCallback, useState } from "react";
import {
  useAllCompanyOfficesQuery,
  useCreateCompanyOfficeMutation,
} from "@codegen/index";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import CountrySelect from "@components/formCountrySelect";
import { EmployerOnboardingPageType } from "@pages/onboarding/types";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { CreateCompanyOfficeInput } from "@codegen/schema";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { useEmployerOnboarding } from '@pages/onboarding/employer-onboarding';

const companyAddressSchema = z.object({
  street: z.string().min(1, { message: "Required" }),
  city: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
  country: z.string().min(1, { message: "Required" }),
  zip: z.string().min(1, { message: "Required" }),
  label: z.string().min(1, { message: "Required" }),
});

type CompanyAddressValues = z.infer<typeof companyAddressSchema>;

export const AddBeneficiaryWorksiteForm = () => {
  const { onSubmit, company, onBack, onSkip } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [createOffice] = useCreateCompanyOfficeMutation()
  const { data: officeData } = useAllCompanyOfficesQuery({
    variables: {
      id: company.value
    }
  })
  const existingWorksites = officeData?.companyById?.companyOfficesByCompanyId?.nodes
  const hasExistingWorksites = existingWorksites && existingWorksites.length > 0
  const form = useForm<CompanyAddressValues>({
    resolver: existingWorksites && existingWorksites.length > 0 ? undefined : zodResolver(companyAddressSchema),
  });
  const { formState } = form
  const logError = useLogError()


  const doSubmitCompanyAddress = useCallback(
    async (data: CompanyAddressValues) => {
      try {
        if (company == null) {
          throw 'Company is not provided'
        }

        if (formState.isValid && data.street) {
          setStatus("loading");
          const { errors } = await createOffice({
            variables: {
              input: {
                clientMutationId: '',
                companyOffice: {
                  companyId: parseInt(company.value),
                  city: data.city,
                  country: data.country,
                  state: data.state,
                  street: data.street,
                  postalCode: data.zip,
                  label: data.label,
                  isEmployeeWorksite: true
                } as CreateCompanyOfficeInput['companyOffice'],
              },
            },
          });

          if (errors != null) {
            setStatus("error");
            throw errors
          }
          onSubmit({data, key: 'newWorksiteAddress'});
        } else {
          onSkip?.()
        }
      } catch (exception) {
        logError(exception, {
          company: company
        })
        setStatus('error')
      }
    },

    [onSubmit, company, setStatus, hasExistingWorksites, onSkip, formState]
  );

  return (
    <EmployerOnboardingContainer
      title="Company and Worksite Address"
      subtitle={
        <div>
          Founders, if you don’t have a confirmed office address, please include a temporary worksite address (e.g., WeWork or similar). We do not recommend using a home address as this is not proof of an official work location.
        </div>
      }
      cardTitle={"Please add a worksite for your employee"}
      progress={
        0
      }
      form={form}
      onSubmit={doSubmitCompanyAddress}
      name="add_worksite"
    >
      <FieldsContainer status={status}>
        {
          existingWorksites && (
            <div>
              <FormLabel className="mb-2">Existing worksites</FormLabel>
              <ul>
                {
                  existingWorksites.map((of => (
                    <li className="bg-white border rounded px-3 py-2 mb-2">
                      <div>
                        <b>{of?.label}</b>
                      </div>
                      <div>{of?.street} {of?.city} {of?.state}</div>
                    </li>
                  )))
                }
              </ul>
            </div>
          )
        }
        {
          hasExistingWorksites ? <FormLabel className="font-semibold text-black">Add a new worksite if applicable</FormLabel> : null
        }
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className="h-11"
                  placeholder="123 Main St."
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="street"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} className="h-11" placeholder="City" />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="city"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
          )}
        />
        <div className="w-full flex flex-row gap-x-2">
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormControl>
                  <Input
                    {...field}
                    className="h-11 w-full"
                    placeholder="State"
                  />
                </FormControl>
                <ErrorMessage
                  errors={formState.errors}
                  name="state"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <CountrySelect
                  field={field}
                  onSelect={(x: string) => form.setValue("country", x)}
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="country"
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="zip"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} className="h-11" placeholder="Zip code" />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="zip"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
          )}
        />
        <FormLabel className="mt-3">Worksite name</FormLabel>
        <FormField
          control={form.control}
          name="label"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  className="h-11"
                  placeholder="Building 32"
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="label"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
          )}
        />
        {/* <ErrorMessage
                      errors={formState.errors}
                      name={`links.${index}`}
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    /> */}
      </FieldsContainer>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!form.formState.isValid && (officeData?.companyById?.companyOfficesByCompanyId?.nodes?.length ?? 0) < 1}
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer >
  );
};

