import { WorkflowExhibit, WorkflowModule } from "@codegen/enums";
import { Onboarding, Petition, PetitionFragment } from "@codegen/schema";
import { PlymouthUser, StrategyExhibit, StrategyModule, Team } from "@utils/types";
import * as React from "react";
import { z } from 'zod'
export const userFormList = <const>[
  "personal",
  "homeCountryAddress",
  "usAddress",
  "passport",
  "immigrationDocs",
];

export type UserForm = (typeof userFormList)[number];

export const teamFormList = <const>["team", "teamAddress"];

export const companyFormList = <const>[
  "personal",
  "address",
  "certificateOfIncorporationAndArticlesOfIncorporation",
  "businessLicense",
  "feinDocument",
  "companyOverview",
  "bankStatement",
  "companyBylaws",
  "journey",
];

export type CompanyForm = (typeof companyFormList)[number];

export type EmployerOnboardingPageType = {
  onBack?: () => void
  onSkip?: () => void
  onSubmit: (data?: any) => void
  contact?: PlymouthUser
  company: Team
  footnote?: React.ReactNode
}

export type OnboardingPageType = {
  onBack?: () => void
  onSkip?: () => void
  onSubmit: (data?: any) => void
  saveData: (module: WorkflowModule, exhibit: WorkflowExhibit, data?: any) => void
  company?: Team
  contact: PlymouthUser
  exhibit: StrategyExhibit
  module: StrategyModule
  footnote?: React.ReactNode
  petition: PetitionFragment
  onboarding: Pick<Onboarding, 'id' | 'status' | 'modules'>
}

export const YesNo = z.enum(['yes', 'no'])

export type OnboardingModuleWorkflowProps = {
  // onComplete: () => void
  // onPrevious: () => void
}