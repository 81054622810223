import { useCallback, useMemo, useState } from "react";
import { Button } from "@components/button";
import Uploader from "@components/uploader/uploader";
import Spinner from "@components/spinner";
import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { useJwt } from "@utils/hooks";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { api } from "@utils/api";
import * as React from 'react';
import { useEmployerOnboarding } from '../../employer-onboarding';

export const EmployerFundingDocumentsForm = () => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [fundingDocUploaded, setFundingDocUploaded] = useState(false);

  const token = useJwt();

  const { data, loading } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value ?? '',
    },
  })

  const employerFundingDoc = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if ([CompanyDocumentType.FundingDocument, CompanyDocumentType.BankStatement, CompanyDocumentType.SafeDocumentation, CompanyDocumentType.TermSheet].includes(doc.type)) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  React.useEffect(() => {
    if (employerFundingDoc == null) return;
    if (employerFundingDoc.length > 0) setFundingDocUploaded(true);
  }, [employerFundingDoc]);

  const uploadEmployerFundingDoc = useCallback(
    async (file: File) => {
      
      const data = await api.files.uploadCompanyDoc(
        file,
        token,
        company?.value ?? '',
        CompanyDocumentType.FundingDocument
      );
      if (data.success) setFundingDocUploaded(true);

      return {
        success: true,
        link: "",
      };
    },
    [company?.value ?? '', token]
  );

  return (
    <EmployerOnboardingContainer
      title="Employer Funding Documents"
      subtitle="As part of the application, we include evidence that the company can cover expenses for business operations for the duration of the visa. Please upload any one of the following:"
      cardTitle={"Please upload any of the following:"}
      progress={
        0
      }
      name="employer_funding_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <ul className="mb-3 list-disc px-4 gap-2">
              <li>
                U.S. Company Corporate Bank Statement
              </li>
              <li>
                Termsheet / Funding Evidence
              </li>
              <li>
                SAFE
              </li>
              <li>Existing Customer Contracts</li>
            </ul>

            {employerFundingDoc == null && <Spinner />}
            {employerFundingDoc != null && !loading && (
              <Uploader
                multiple
                onFileUpload={uploadEmployerFundingDoc}
                defaultFiles={employerFundingDoc.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
        </div>
      </div>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!fundingDocUploaded}
          onClick={() => onSubmit(employerFundingDoc?.map(doc => doc.id))}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
