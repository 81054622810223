import { Team } from "@utils/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@components/button";
import Uploader from "@components/uploader/uploader";
import Spinner from "@components/spinner";
import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { useJwt } from "@utils/hooks";
import { api } from "@utils/api";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { EmployerOnboardingPageType } from "@pages/onboarding/types";
import { useLogError } from "@utils/error";
import { useEmployerOnboarding } from '../../employer-onboarding';

export const CorporateDocumentsForm = () => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [aoiUploaded, setAOIUploaded] = useState(false);
  const [taxIdUploaded, setTaxIdUploaded] = useState(false);
  const logError = useLogError()

  const token = useJwt();

  const { data, loading } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value ?? '',
    },
  });

  const articlesOfIncorporation = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.ArticlesOfIncorporation) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);

  const taxIdEINDocument = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.TaxIdFeinDocument) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);

  useEffect(() => {
    if (taxIdEINDocument == null) return;
    if (taxIdEINDocument.length > 0) setTaxIdUploaded(true);
  }, [taxIdEINDocument]);

  useEffect(() => {
    if (articlesOfIncorporation == null) return;
    if (articlesOfIncorporation.length > 0) setAOIUploaded(true);
  }, [articlesOfIncorporation]);

  const onEINUpload = useCallback(
    async (file: File) => {
      const data = await api.files.uploadCompanyDoc(
        file,
        token,
        company?.value ?? '',
        CompanyDocumentType.TaxIdFeinDocument
      );

      if (data.success) setTaxIdUploaded(true);
      const { id } = data

      try {
        if (id) {
          api.utils.ocr(id, token)
        }
      } catch (error) {
        logError(error)
      }

      return {
        success: data.success,
        link: "",
      };
    },
    [company?.value, token]
  );

  const onAOIUpload = useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadCompanyDoc(
          file,
          token,
          company?.value ?? '',
          CompanyDocumentType.ArticlesOfIncorporation
        );
        if (data.success) setAOIUploaded(true);
        const { id } = data

        try {
          if (id) {
            api.utils.ocr(id, token)
          }
        } catch (error) {
          logError(error)
        }

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception)
        return {
          success: false,
          link: ""
        }
      }
    },
    [company?.value, token]
  );

  return (
    <EmployerOnboardingContainer
      title="Corporate Documents: The Building Blocks"
      subtitle=""
      cardTitle={"Please upload the following:"}
      progress={
        0
      }
      name="corporate_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <Button variant="link" className="pl-0">
              Articles of Incorporation
            </Button>
            {articlesOfIncorporation == null && <Spinner />}
            {articlesOfIncorporation != null && !loading && (
              <Uploader
                multiple
                onFileUpload={onAOIUpload}
                defaultFiles={articlesOfIncorporation.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>

          <div>
            <Button variant="link" className="pl-0">
              EIN Document
            </Button>

            {taxIdEINDocument == null && <Spinner />}
            {taxIdEINDocument != null && !loading && (
              <Uploader
                multiple
                onFileUpload={onEINUpload}
                defaultFiles={taxIdEINDocument.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
        </div>
      </div>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={!aoiUploaded || !taxIdUploaded}
          onClick={() => onSubmit(articlesOfIncorporation?.map(d => d.id).concat((taxIdEINDocument ?? [])?.map(d => d.id)))}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
