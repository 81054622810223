import React, { createContext, useContext, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useActorRef } from '@xstate/react';
import { OnboardingContext, onboardingMachine, usePersistedOnboardingMachine } from './employer/machines';

// Import all the forms
import { WelcomePage } from './employer/forms/welcome';
import { ConfirmContactForm } from './employer/forms/confirm-contact';
import { NewContactForm } from './employer/forms/add-new-contact';
import { CorporateDocumentsForm } from './employer/forms/corporate-docs';
import { ConfirmEmployerHQController } from './employer/forms/confirm-employer-hq';
import { AddBeneficiaryWorksiteForm } from './employer/forms/add-beneficiary-worksite';
import { EmployerHistoryFormController } from './employer/forms/employer-history';
import { EmployerFundingDocumentsForm } from './employer/forms/employer-funding-docs';
import { ForeignDocumentsForm } from './employer/forms/foreign-docs';
import Conclusion from './employer/forms/conclusion';
import { useStatsigClient } from '@statsig/react-bindings';
import { useContact } from '@utils/hooks';
import { WaitingSplash } from './shared/waiting';
import { PlymouthUser, Team } from '@utils/types';
import { camelCase, kebabCase } from 'change-case';
import { useCompanyMemberByCompanyIdAndUserIdQuery } from '@codegen/index';
import { Snapshot } from 'xstate';

// Define the context type
type EmployerOnboardingContextType = {
  company: Team;
  contact: PlymouthUser;
  onSubmit: (data: any) => void;
  onSkip: () => void;
  onBack: () => void;
  companyMember: {
    companyId: string;
    isAuthorizedSignatory: boolean | null;
    userId: string;
  } | null | undefined
};

const ROOT_PATH = '/onboarding/employer'
// Create the context
const EmployerOnboardingContext = createContext<EmployerOnboardingContextType | undefined>(undefined);

// Create a provider component
function EmployerOnboardingProvider({ children, company, contact, companyMember }: Omit<EmployerOnboardingContextType, 'onSkip' | 'onBack' | 'onSubmit'> & { children: React.ReactNode }) {

  const initialContact = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phone: contact.phoneNumber,
    isAuthorizedSignatory: companyMember?.isAuthorizedSignatory ? 'yes' : 'no'
  };
  const { getOnboardingState, setOnboardingState } = usePersistedOnboardingMachine(company.value)
  const path = useLocation()
  const currentPath = path.pathname.split('/')?.[3]
  const persistedState = getOnboardingState()

  const resolvedState = onboardingMachine.resolveState(persistedState ?? {
    value: currentPath ? currentPath === 'complete' ? 'final' : camelCase(currentPath) : 'welcome',
    context: {
      currentContact: initialContact,
      companyHQAddress: company.hqAddress
    }
  })
  const actor = useActorRef(onboardingMachine, {
    snapshot: resolvedState
  });

  const [currentPage, setCurrentPage] = React.useState<string>()
  const [_, setCurrentContext] = React.useState<OnboardingContext | undefined>()
  const { client } = useStatsigClient();
  const navigate = useNavigate();
  React.useEffect(() => {
    const subscription = actor.subscribe((snapshot) => {
      setCurrentContext(snapshot.context)
      setCurrentPage(snapshot.value)
    });

    return subscription.unsubscribe;
  }, [actor]);

  const goToPage = (page: string) => {
    const persistedState = actor.getPersistedSnapshot()
    if (persistedState && persistedState.value !== 'welcome') {
      setOnboardingState(persistedState as Snapshot<OnboardingContext>)
    }

    if (page === 'final') {
      navigate(ROOT_PATH + '/complete')
    } else {
      navigate(ROOT_PATH + '/' + kebabCase(page))
    }
  }

  const handleNext = React.useCallback(async (newData?: { data: any, key: keyof OnboardingContext }) => {
    const { data, key } = newData ?? {}
    if (data && key) {
      actor.send({ type: 'SET_DATA', key, value: data });
    }
    const currentState = actor.getSnapshot().value
    client.logEvent('employer_onboarding', currentState, {
      action: 'next'
    })
    actor.send({ type: 'NEXT' });

    const nextState = actor.getSnapshot().value
    goToPage(nextState)
  }, [actor])

  const handleSkip = React.useCallback(() => {
    actor.send({ type: 'NEXT' });
    const nextState = actor.getSnapshot().value
    goToPage(nextState)
  }, [actor])

  const onBack = React.useCallback(() => {
    client.logEvent('employer_onboarding', currentPage, {
      action: 'back'
    })
    actor.send({ type: 'BACK' })
    const nextState = actor.getSnapshot().value

    goToPage(nextState)
  }, [actor, currentPage])

  return (
    <EmployerOnboardingContext.Provider value={{
      company, contact, onSubmit: handleNext, onBack, onSkip: handleSkip, companyMember
    }}>
      {children}
    </EmployerOnboardingContext.Provider>
  );
}

// Custom hook to use the context
export function useEmployerOnboarding() {
  const context = useContext(EmployerOnboardingContext);
  if (context === undefined) {
    throw new Error('useEmployerOnboarding must be used within an EmployerOnboardingProvider');
  }
  return context;
}

export function EmployerOnboarding() {
  const { contact, loading, team } = useContact();
  const { loading: companyMemberLoading, data: companyMemberData } = useCompanyMemberByCompanyIdAndUserIdQuery({
    variables: {
      userId: contact?.id ?? '',
      companyId: team?.value ?? ''
    }
  })
  if (loading || !contact || !team || companyMemberLoading) {
    return <WaitingSplash />
  }

  return (
    <EmployerOnboardingProvider company={team} contact={contact} companyMember={companyMemberData?.companyMemberByCompanyIdAndUserId}>
      <Routes>
        <Route path="/confirm-contact" element={<ConfirmContactForm />} />
        <Route path="/add-contact" element={<NewContactForm />} />
        <Route path="/corporate-docs" element={<CorporateDocumentsForm />} />
        <Route path="/confirm-address" element={<ConfirmEmployerHQController />} />
        <Route path="/add-worksite" element={<AddBeneficiaryWorksiteForm />} />
        <Route path="/employer-history" element={<EmployerHistoryFormController />} />
        <Route path="/employer-funding-docs" element={<EmployerFundingDocumentsForm />} />
        <Route path="/foreign-docs" element={<ForeignDocumentsForm />} />
        <Route path="/complete" element={<Conclusion />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<WelcomePage />} />
      </Routes>
    </EmployerOnboardingProvider>
  );
}
