import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@components/button";
import Uploader from "@components/uploader/uploader";
import Spinner from "@components/spinner";
import { useAllCompanyDocsQuery } from "@codegen/index";
import { CompanyDocumentType } from "@codegen/schema";
import { useJwt } from "@utils/hooks";
import { api } from "@utils/api";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { Info } from "lucide-react";
import { HoverCard, HoverCardArrow, HoverCardContent, HoverCardTrigger } from "@components/hoverCard";
import { useEmployerOnboarding } from '../../employer-onboarding';

export const ForeignDocumentsForm = () => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [businessLicenseUploaded, setBusinessLicenseUploaded] = useState(false);

  const token = useJwt();

  const { data, loading } = useAllCompanyDocsQuery({
    variables: {
      companyId: company?.value ?? '',
    },
  });

  const businessLicense = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.BusinessLicense) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);
  const nameChangeDocument = useMemo(() => {
    if (data == null || data.allCompanyDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of data.allCompanyDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type !== CompanyDocumentType.NameChange) continue;
      res.push({
        id: doc.fileByFileId.id ?? "",
        name: doc.fileByFileId.name,
      });
    }
    return res;
  }, [data]);

  useEffect(() => {
    if (businessLicense == null) return;
    if (businessLicense.length > 0) setBusinessLicenseUploaded(true);
  }, [businessLicense, setBusinessLicenseUploaded]);

  const uploadBusinessLicense = useCallback(
    async (file: File) => {
      const data = await api.files.uploadCompanyDoc(
        file,
        token,
        company?.value ?? '',
        CompanyDocumentType.BusinessLicense
      );

      if (data.success) setBusinessLicenseUploaded(true);
      return {
        success: data.success,
        link: "",
      };
    },
    [company?.value ?? '', token]
  );
  const uploadNameChangeDocument = useCallback(
    async (file: File) => {
      const data = await api.files.uploadCompanyDoc(
        file,
        token,
        company?.value ?? '',
        CompanyDocumentType.NameChange
      );

      if (data.success) setBusinessLicenseUploaded(true);
      return {
        success: data.success,
        link: "",
      };
    },
    [company?.value ?? '', token]
  );

  return (
    <EmployerOnboardingContainer
      title="Corporate Documents: The Building Blocks"
      subtitle=""
      cardTitle={"Please upload the following:"}
      progress={
        0
      }
      name="foreign_docs"
    >
      <div className="flex flex-col gap-y-4 w-[500px]">
        <div className="flex flex-col gap-y-4 w-[500px]">
          <div>
            <div className="flex gap-2 items-center">
              <label>Registration of Out of State Corporation (if applicable)</label>
              <HoverCard>
                <HoverCardTrigger>
                  <Button type="button" variant="link" className="text-md p-0">
                    <Info size={16} />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent className="w-[450px]">
                  <div>
                    <p className="mb-2">If your company is headquartered in a state where you don't plan to operate, you may need to register as a foreign corporation in your operational state.</p>
                    <p className="font-bold mb-2">Example: Companies incorporated in Delaware but operating in California must do this. If you don’t have this, please find instructions here to apply for one. This usually takes 2-3 weeks.</p>
                    <p>Why? It keeps your business compliant with state laws.</p>
                    <p>You can checkout a foreign entity guide <a target="_blank" className="text-accent font-bold" href="https://docs.google.com/document/d/1CIx49cfWzjzRu6nhC8ZZnUtcqzwCre1DD4JWgtybN8I/edit?usp=sharing">here</a>.</p>
                  </div>
                  <HoverCardArrow className="fill-white" />
                </HoverCardContent>
              </HoverCard>
            </div>

            {businessLicense == null && <Spinner />}
            {businessLicense != null && !loading && (
              <Uploader
                multiple
                onFileUpload={uploadBusinessLicense}
                defaultFiles={businessLicense.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
          <div>
            <div className="flex gap-2 items-center">
              <Button type="button" variant="link" className="text-md p-0">Corporate Name Change document (if applicable)</Button>
            </div>

            {nameChangeDocument == null && <Spinner />}
            {nameChangeDocument != null && !loading && (
              <Uploader
                multiple
                onFileUpload={uploadNameChangeDocument}
                defaultFiles={nameChangeDocument.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>

        </div>
      </div>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          // disabled={!businessLicenseUploaded}
          onClick={() => onSubmit({data: [businessLicense?.map(d => d.id)], key: 'foreignDocsFileIds'})}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
