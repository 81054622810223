import { useAllImmigrationDocsByUserQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { ErrorMessage } from '@hookform/error-message';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

export const passportFormSchema = z.object({
  passports: z.string().min(1, 'Please upload your passport')
});

export type PassportFormType = z.infer<typeof passportFormSchema>;
export const PassportForm: React.FC<OnboardingPageType> = ({ onBack, onSubmit, onSkip, contact, exhibit }) => {
  const [passportUploaded, setPassportUploaded] = React.useState(false);
  const logError = useLogError()
  const methods = useForm({
    defaultValues: {
      passports: []
    },
  });

  const { data: immigrationDocsData, loading } = useAllImmigrationDocsByUserQuery({
    variables: {
      ownerId: contact.id
    }
  })

  const handleSubmit = (data: PassportFormType) => {
    onSubmit(data)
  }
  const token = useJwt();
  const { formState } = methods
  const passportUpload = React.useMemo(() => {
    if (immigrationDocsData == null || immigrationDocsData?.allImmigrationDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of immigrationDocsData.allImmigrationDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type === ImmigrationDocumentType.Passport) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [immigrationDocsData]);

  const onPassportUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadImmigrationDoc(
          file,
          token,
          contact.id,
          ImmigrationDocumentType.Passport
        );
        if (data.success) setPassportUploaded(true);
        const { id } = data

        try {
          const ocrRes = await api.utils.ocr(id, token)
          if (!ocrRes) {
            throw `Error ocr file: ${id} of type Passport`
          }
        } catch (error) {
          logError(error)
        }

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception, {
          contactId: contact.id,
          docType: ImmigrationDocumentType.Passport
        })
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token]
  );

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={"Identification information"}
        subtitle={"If you have multiple passports, please upload the passport from your birth country as well as the passport you intend to use for travel."}
        cardTitle={"Upload passport"}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`passport-form`}
      >
        <FieldsContainer status={''}>
          <div>
            <Button variant="link" className="pl-0">
              Passports
            </Button>
            {passportUpload == null && <Spinner />}
            {passportUpload != null && (
              <Uploader
                multiple
                onFileUpload={onPassportUpload}
                defaultFiles={passportUpload.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
            <ErrorMessage
              errors={formState.errors}
              name="passports"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            type="submit"
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
}

export default PassportForm;
